import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';

import './assets/css/reset.css';

const url = "https://api.back.reports.paul-cloud.de";

axios.defaults.baseURL = url;
axios.defaults.headers.common['Accept'] = "application/json";
axios.defaults.headers.common['Content-Type'] = "application/json";

createApp(App).use(store).use(router).mount('#app')
