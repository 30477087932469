import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

export const state = {
  jobs: []
};

export const jobs = {
  state,
  getters,
  actions,
  mutations
};
