import axios from "axios";

export const actions = {
    async getJobs({ commit }, payload) {
        let url = '/jobs';
        if (payload) {
            console.log(payload)
            const query = Object.keys(payload).map(key => {
                return `${ key }=${ payload[key] }`;
            }).join('&');
            url = `${ url }?${ query }`;
        }
        const response = await axios.get(
            url,
            {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }
        );
        commit("getJobs", response.data);
    },

    async removeJob({ commit }, payload) {
        const response = await axios.delete(
            "/jobs/" + payload._id,
            {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }
        );
        commit("getJobs", response.data);
    },

    async updateJob({ commit }, payload) {
        await axios.put(
            "/jobs", payload,
            {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }
        );
    },

    async download({ commit }, data) {
        const response = await axios.get(
            "/jobs/report/" + data.id,
            {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                responseType: 'blob'
            }
        );
        if (response.data.error) {
            console.error(response.data.error)
        }

        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', data.name + ".csv");
        document.body.appendChild(fileLink);
        fileLink.click();
        fileLink.remove();
    }
};
