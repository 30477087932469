import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Login from '../views/Auth/Login.vue';
import Admin from '../components/Admin.vue';


const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "login",
    component: Login,
    meta: {
      title: 'Login',
    },
    beforeEnter (to, from, next) {
      if (localStorage.getItem('token')) {
        next('/jobs');
      } else {
        next();
      }
    }
  },
  {
    path: "/",
    redirect: "jobs",
    component: Admin,
    children: [
      {
        path: "/jobs",
        name: "Jobs",
        meta: {
          title: 'Jobs',
        },
        component: () => import( "../views/Job/Jobs.vue")
      },
      {
        path: "/jobs/job",
        name: "New Job",
        meta: {
          title: 'Neuer Job',
        },
        component: () => import( "../views/Job/NewJob.vue"),
      }
    ],
    beforeEnter(to, from, next) {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next('/')
      }
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  linkExactActiveClass: "active",
  routes
})

export default router
