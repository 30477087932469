import Vuex from "vuex";

import login from "./modules/login";
import { job } from "./modules/job/index";
import { jobs } from "./modules/jobs/index";

export default new Vuex.Store({
  modules: {
    login: login,
    job,
    jobs
  }
});
