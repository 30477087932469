import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

export const state = {
  job: null
};

export const job = {
  state,
  getters,
  actions,
  mutations
};
