import axios from "axios";

export const actions = {
  async saveJob({ commit }, payload) {
    const response = await axios.post(
      "/jobs",
      payload,
      { headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      }}
    );
    commit("saveJob", response.data);
  }
};